import { FC, useContext, useMemo } from 'react';
import { Steps } from '@maxtropy/components';
import { PvStepContext } from '../ArrayInfo';

export interface StepProps {
  step?: number;
}

const PvSteps: FC<StepProps> = props => {
  const context = useContext(PvStepContext);
  const { step } = props;
  const stepList = useMemo(() => {
    return [
      {
        label: '并网点',
        id: 0,
        show: true,
      },
      {
        label: '汇流箱',
        id: 1,
        show: true,
      },
      {
        label: '逆变器',
        id: 2,
        show: true,
      },
      {
        label: '组串',
        id: 3,
        show: true,
      },
      {
        label: '组件',
        id: 4,
        show: context.hasComponentBox,
      },
    ].filter(item => item.show);
  }, [context.hasComponentBox]);
  return (
    <Steps current={step} progressDot={true}>
      {stepList.map(item => (
        <Steps.Step key={item.id} description={item.label} />
      ))}
    </Steps>
  );
};

export default PvSteps;
