import React from 'react';
import { Tag } from '@maxtropy/components';

interface Iprops {
  status: boolean;
}
const StatusTag: React.FC<Iprops> = ({ status }) => {
  return (
    <Tag border="solid" color={status ? 'success' : 'error'}>
      {status ? '成功' : '失败'}
    </Tag>
  );
};
export default StatusTag;
