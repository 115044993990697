import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';

export interface OuList {
  id: number;
  name: string;
  serialNumber: string;
}

export interface OrgOuTreeRes {
  customerName: string;
  mcid: number;
  ouList: OuList[];
}

export interface OrgOuTreeReq {
  ouName?: string;
}

export interface ProjectInfoReq {
  ouId: Key;
}

// 组织-ou树
export function getcustomerOuTree(params: OrgOuTreeReq) {
  return fetch<OrgOuTreeRes[]>(
    `/api/energy-asset-project/customer-ou-tree?${qs.stringify(params, { indices: false })}`
  );
}

export interface ProjectInfoRes {
  pvInstalledCapacity: number;
  bsaInstalledCapacity: number;
  bsaRatedPower: number;
  evInstalledCapacity: number;
  directorName: string;
  projectAddress: string;
}

// 项目信息
export function getEnergyAssetProjectInfo(params: ProjectInfoReq) {
  return fetch<ProjectInfoRes>(`/api/energy-asset-project/project-info?${qs.stringify(params, { indices: false })}`);
}

export interface StationInfoRes {
  pvChargeByDay: number; //光伏站今日发电量
  bsaBeChargeByDay: number; //储能站今日充电量
  bsaDisChargeByDay: number; //储能站今日放电量
  evBeChargeByDay: number; //充电站今日充电量
}

// 场站信息
export function getEnergyAssetStationInfo(params: ProjectInfoReq) {
  return fetch<StationInfoRes>(
    `/api/energy-asset-project/station-energy-info?${qs.stringify(params, { indices: false })}`
  );
}

export interface CurrentMonIncomeInfoRes {
  totalIncomeThisMonth: number; //本月总收益
  pvTotalIncomeThisMonth: number; //本月光伏总收益
  bsaTotalIncomeThisMonth: number; //本月储能总收益
  evTotalIncomeThisMonth: number; //本月充电站总收益
}

// 查询本月收益情况
export function getEnergyAssetCurrentMonIncomeInfo(params: ProjectInfoReq) {
  return fetch<CurrentMonIncomeInfoRes>(
    `/api/energy-asset-project/month-income-overview?${qs.stringify(params, { indices: false })}`
  );
}

export interface TotalIncomeInfoRes {
  id: number; // 站点id
  stationName: string; // 站点名称
  type: string; // 站点类型
  cumulativeGain: number; // 累计收益
  revenueProportion: number; // 总收益占比
  isNew?: boolean; // 区分新旧储能
}

// 累计收益信息
export function getEnergyAssetTotalIncomeInfo(params: ProjectInfoReq) {
  return fetch<TotalIncomeInfoRes[]>(
    `/api/energy-asset-project/accumulated-income-info?${qs.stringify(params, { indices: false })}`
  );
}

export interface PvIncome {
  income: number;
  incomeRate: number;
}

export interface BsaIncome {
  income: number;
  incomeRate: number;
}

export interface EvIncome {
  income: number;
  incomeRate: number;
}

export interface CurrentThirtyDaysIncomeInfoRes {
  incomeDate: number; // 收益日期
  incomeCount: number; // 总收益
  pvIncome: PvIncome; // 光伏相关收益
  bsaIncome: BsaIncome; // 储能相关收益
  evIncome: EvIncome; // 充电站相关收益
}

// 近30日收益
export function getEnergyAssetCurrentThirtyDaysIncomeInfo(params: ProjectInfoReq) {
  return fetch<CurrentThirtyDaysIncomeInfoRes[]>(
    `/api/energy-asset-project/financial-thirty-days?${qs.stringify(params, { indices: false })}`
  );
}

export interface InvestmentRecoverySituationInfoRes {
  totalInvestment: number; // 总投资
  recycled: number; // 已收回
  recycledPercentage: number; // 已收回百分比
  profit: number; // 收回投资后盈利
  recoveryDate: string; // 完成投资收回日期
}

// 投资回收情况
export function getEnergyAssetInvestmentRecoverySituationInfo(params: ProjectInfoReq) {
  return fetch<InvestmentRecoverySituationInfoRes>(
    `/api/energy-asset-project/investment-recovery-situation?${qs.stringify(params, { indices: false })}`
  );
}
export interface EnergyAssetAlarmInfoRes {
  highestCount: number; // 最高级个数
  advancedCount: number; // 高级个数
  intermediateCount: number; // 中级个数
  lowCount: number; // 低级个数
  lowestCount: number; // 最低级个数
  assetCodes: string[]; // 光储充的所有assetCode
}
// 项目报警信息
export function getEnergyAssetAlarmInfo(params: ProjectInfoReq) {
  return fetch<EnergyAssetAlarmInfoRes>(
    `/api/energy-asset-project/project-alarm-info?${qs.stringify(params, { indices: false })}`
  );
}
export interface EnergyAssetThirtyDaysPVInfoRes {
  datetime: number; // 日期
  electricityQuantity: number; // 充、放、发电量
  electricityQuantityRate: number; // 环比
}
// 近30日光伏发电量
export function getEnergyAssetThirtyDaysPVInfo(params: ProjectInfoReq) {
  return fetch<EnergyAssetThirtyDaysPVInfoRes[]>(
    `/api/energy-asset-project/pv-charge-thirty-days?${qs.stringify(params, { indices: false })}`
  );
}
export interface BsaBeChargeInfo {
  datetime: number;
  electricityQuantity: number;
  electricityQuantityRate: number;
}

export interface BsaDisChargeInfo {
  datetime: number;
  electricityQuantity: number;
  electricityQuantityRate: number;
}

export interface EnergyAssetThirtyDaysBsaInfoRes {
  bsaBeChargeInfo: BsaBeChargeInfo[];
  bsaDisChargeInfo: BsaDisChargeInfo[];
}
// 近30日储能站发电量
export function getEnergyAssetThirtyDaysBsaInfo(params: ProjectInfoReq) {
  return fetch<EnergyAssetThirtyDaysBsaInfoRes>(
    `/api/energy-asset-project/bsa-eq-thirty-days?${qs.stringify(params, { indices: false })}`
  );
}

export interface ChargeStationRes {
  datetime: number;
  electricityQuantity: number;
  electricityQuantityRate: number;
}
// 近30日充电站发电量
export function getEnergyAssetThirtyDaysChargeStationInfo(params: ProjectInfoReq) {
  return fetch<ChargeStationRes[]>(
    `/api/energy-asset-project/ev-charge-thirty-days?${qs.stringify(params, { indices: false })}`
  );
}

export interface StationCountRes {
  bsaCount: boolean;
  pvCount: boolean;
  evCount: boolean;
}

// 获取光储充的数量状态
export function getEnergyAssetStationCount(params: ProjectInfoReq) {
  return fetch<StationCountRes>(
    `/api/energy-asset-project/getStationCount?${qs.stringify(params, { indices: false })}`
  );
}
