import classnames from 'classnames/bind';
import { useState } from 'react';
import { EPricePeakType, EPriceTime, E_PRICE_PEAK_TYPE_COLORS_MAP } from '../../api/electricityPrice';
import styles from './index.module.scss';
import { getItemTime, getPeriodTime, getPeriodTypes } from './utils';
import { Tooltip } from '@maxtropy/components';

const cx = classnames.bind(styles);

interface EPricePeakItemProps {
  times?: EPriceTime[];
  peakType?: EPricePeakType;
  onChange?: (times: EPriceTime[]) => void;
  disabled?: boolean;
}

const EPricePeakItem: React.FC<EPricePeakItemProps> = ({ times = [], peakType, onChange, disabled = false }) => {
  const [range, setRange] = useState<[number | undefined, number | undefined]>([undefined, undefined]);
  const [start, end] = range;
  const getItemColor = (index: number) => {
    const [tempStart, tempEnd] = [start, end].sort((a, b) => (a || 0) - (b || 0));
    if (tempStart !== undefined && index >= tempStart && tempEnd !== undefined && index <= tempEnd) {
      return `${E_PRICE_PEAK_TYPE_COLORS_MAP[peakType!]}cc`;
    }
    const time = times.find(time => time.intervalStart <= index && time.intervalEnd >= index);
    if (time) {
      return E_PRICE_PEAK_TYPE_COLORS_MAP[time.type];
    }
    return 'rgba(var(--mx-main-color), 0.2)';
  };

  const onItemClick = (index: number) => {
    if (!peakType) {
      return;
    }
    if (start === undefined) {
      setRange([index, index]);
      return;
    }

    const [tempStart, tempEnd] = [start, index].sort((a, b) => a - b);
    onChange?.(
      getPeriodTypes([
        ...times,
        {
          type: Number(peakType),
          intervalStart: tempStart,
          intervalEnd: tempEnd,
        },
      ]).filter((time: EPriceTime) => time.type !== undefined)
    );

    setRange([undefined, undefined]);
  };

  const onItemMouseEnter = (index: number) => {
    if (!peakType) {
      return;
    }
    if (start === undefined) {
      return;
    }
    setRange([start, index]);
  };

  return (
    <div className={cx('peak-item')}>
      {!disabled
        ? [...new Array(48)].map((_, index) => (
            <Tooltip title={`${getItemTime(index)}~${getItemTime(index + 1)}`} key={index}>
              <span
                className={cx('bar')}
                style={{ background: getItemColor(index) }}
                onClick={() => onItemClick(index)}
                onMouseEnter={() => onItemMouseEnter(index)}
              />
            </Tooltip>
          ))
        : getPeriodTypes(times).map((item, index) => {
            if (item.type) {
              return (
                <Tooltip title={getPeriodTime(item)} key={index}>
                  {[...new Array(item.intervalEnd - item.intervalStart + 1)].map((_, i) => (
                    <span
                      key={i}
                      className={cx('bar')}
                      style={{
                        background: E_PRICE_PEAK_TYPE_COLORS_MAP[item.type],
                      }}
                    />
                  ))}
                </Tooltip>
              );
            }
            return [...new Array(item.intervalEnd - item.intervalStart + 1)].map((_, i) => (
              <span key={i} className={cx('bar')} style={{ background: 'rgba(var(--mx-main-color),0.2)' }} />
            ));
          })}
    </div>
  );
};

export default EPricePeakItem;
