import {
  Select,
  Form,
  Input,
  Radio,
  useBreadcrumbRoutes,
  message,
  Wrapper,
  Button,
  SubContent,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { fetchOuList, getOuId } from '@/api/ou';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import SelectCircuitModal from './SelectCircuitModal';
import {
  apiV2LineLossAlarmRuleCreatePost,
  apiV2LineLossAlarmRuleUpdatePost,
  apiV2LineLossAlarmRuleDetailPost,
} from '@maxtropy/dmes-apis-v2';
interface Iprops {
  isEdit?: boolean;
  isMore?: boolean; // 是否批量创建
  isView?: boolean; // 是否查看
}
export enum AlarmLevel {
  HIGHEST,
  HIGH,
  MIDDLE,
  LOW,
  LOWEST,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.HIGHEST]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.LOWEST]: '最低级',
};

export const AlarmLevelColorDisplay = {
  [AlarmLevel.HIGHEST]: 'error',
  [AlarmLevel.HIGH]: 'warning',
  [AlarmLevel.MIDDLE]: 'info',
  [AlarmLevel.LOW]: 'success',
  [AlarmLevel.LOWEST]: 'default',
};
const CreateRules: React.FC<Iprops> = ({ isEdit, isMore }) => {
  const routesContext = useBreadcrumbRoutes();
  const routes = [{ name: isEdit ? '编辑服务端报警' : '新建服务端报警' }];
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [circuitModalVisible, setCircuitModalVisible] = useState(false);
  const [hasSelectedIds, setHasSelectedIds] = useState<number[]>();
  const showPleaseSelect = useMemo(() => hasSelectedIds && hasSelectedIds.length > 0, [hasSelectedIds]);
  const [currentOuId, setCurrentOuId] = useState<number>();
  // 运营单元下拉列表
  const { data: ouList } = useQuery('ouList', async () => {
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (!ouType) return [];
    return fetchOuList(ouType);
  });
  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);
  // 表单回填
  useEffect(() => {
    if (id) {
      apiV2LineLossAlarmRuleDetailPost({ id }).then(res => {
        // 是否存在回路
        if (res.circuitDeleted) {
          setHasSelectedIds([]);
        } else {
          const circuitIdList = [res.circuitId!];
          setHasSelectedIds(circuitIdList);
          form.setFieldValue('circuitIdList', circuitIdList);
        }
        form.setFieldsValue(res);
        setCurrentOuId(res.ouId);
      });
    }
  }, [id, form]);

  // 选择的回路id
  const getCircuitIds = (ids?: number[]) => {
    form.setFieldsValue({
      circuitIdList: ids,
    });
    setHasSelectedIds(ids);
  };
  const saveBtn = () => {
    form.validateFields().then(res => {
      let promise = isEdit ? apiV2LineLossAlarmRuleUpdatePost({ id, ...res }) : apiV2LineLossAlarmRuleCreatePost(res);
      promise.then(() => {
        navigate('/dmes/lineLoss/alarmRules', { replace: true });
      });
    });
  };
  const ouIdOnchange = (ouId: number) => {
    getCircuitIds([]);
    setCurrentOuId(ouId);
  };
  const openCircuitModal = () => {
    if (!currentOuId) {
      message.warning('请先选择所属运营单元');
      return;
    }
    setCircuitModalVisible(true);
  };
  return (
    <>
      <Wrapper className={styles.wrapper} routes={[...(routesContext?.routes ?? []), ...routes]}>
        <Form
          form={form}
          initialValues={{
            alarmLogic: 1,
          }}
        >
          <FormTitle title={isEdit ? '编辑服务端报警' : '新建服务端报警'} />
          <SubContent title="基础设定">
            <Row>
              <Col span={12}>
                <Form.Item label="规则名称" name="name" rules={[{ required: true, message: '请输入规则名称' }]}>
                  <Input maxLength={15} placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                {isEdit ? (
                  <Form.Item label="所属运营单元" name="ouName" rules={[{ required: true, message: '请选择运营单元' }]}>
                    <Select placeholder="请选择" disabled options={ouOptions} showSearch optionFilterProp="label" />
                  </Form.Item>
                ) : (
                  <Form.Item label="所属运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
                    <Select
                      placeholder="请选择"
                      onChange={ouIdOnchange}
                      options={ouOptions}
                      showSearch
                      optionFilterProp="label"
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="alarmLevel" label="报警等级" rules={[{ required: true, message: '请选择报警等级' }]}>
                  <Select placeholder="请选择">
                    <Select.Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Select.Option>
                    <Select.Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Select.Option>
                    <Select.Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Select.Option>
                    <Select.Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Select.Option>
                    <Select.Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="逻辑设定">
            <Row>
              <Col span={12}>
                <Form.Item label="回路" name="circuitIdList" rules={[{ required: true, message: '请选择' }]}>
                  {showPleaseSelect ? (
                    <Space>
                      <span>
                        已经选择<i className={styles.select_num}>{hasSelectedIds?.length}</i>项
                      </span>
                      <Button type="link" style={{ paddingLeft: 0 }} onClick={openCircuitModal}>
                        {isMore ? '继续选择' : '重新选择'}
                      </Button>
                    </Space>
                  ) : (
                    <Button type="link" style={{ paddingLeft: 0 }} onClick={openCircuitModal}>
                      请选择
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="报警逻辑" name="alarmLogic" rules={[{ required: true, message: '请选择' }]}>
                  <Radio.Group>
                    <Radio value={1}>回路线损率及线损量</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="查询周期" name="cycle" rules={[{ required: true, message: '请选择' }]}>
                  <Radio.Group>
                    <Radio value={1}>按日</Radio>
                    <Radio value={2}>
                      按月&nbsp;&nbsp;
                      <Space>
                        <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
                        <span className={styles.tips_text}>查询时间为次日8:00或次月1号8:00</span>
                      </Space>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="损耗率"
                  name="lossRate"
                  rules={[
                    { required: true, message: '请输入' },
                    {
                      pattern: /^100$|^0$|^[1-9]{1}[0-9]{1}$|^[1-9]{1}$|^\d{1,2}((\.[1-9]{1})+|(\.\d{1}[1-9]{1})+)$/,
                      message: '仅能输入0-100数字和小数点, 精确至小数点后两位',
                    },
                  ]}
                >
                  <Input placeholder="请输入" addonAfter="%" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="损耗电量"
                  name="loss"
                  rules={[
                    { required: true, message: '请输入' },
                    { pattern: /^[1-9]\d{0,7}$/, message: '只能输入8位正整数' },
                  ]}
                >
                  <Input placeholder="请输入" maxLength={8} addonAfter="kWh" />
                </Form.Item>
              </Col>
            </Row>
            <div className={styles.tips_text}>
              <InfoCircleOutlined style={{ color: '#F5AC00' }} />
              <span className={styles.txt}>
                逻辑说明：根据设定的统计周期, 查询周期内所选择回路损耗率和损耗电量, 若都大于等于输入的值, 则报警
              </span>
            </div>
          </SubContent>
        </Form>
        <Space
          style={{
            marginLeft: 32,
            marginTop: -13,
          }}
          size={8}
          className={'sticky-footer-left'}
        >
          <Button type="primary" onClick={saveBtn}>
            保存
          </Button>
          <Button onClick={() => navigate(`/dmes/lineLoss/alarmRules`)}>取消</Button>
        </Space>
      </Wrapper>
      {circuitModalVisible && (
        <SelectCircuitModal
          isMore={isMore}
          ids={hasSelectedIds}
          ouId={currentOuId}
          onConfirm={getCircuitIds}
          onCancel={() => setCircuitModalVisible(false)}
        />
      )}
    </>
  );
};
export default CreateRules;
