import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { Spin } from 'antd';
import { Themes, useTheme } from '@maxtropy/components';

const cx = classnames.bind(styles);

interface BorderWrapperProps {
  isLoading?: boolean;
  children?: React.ReactNode;
}

const BorderWrapper: React.FC<BorderWrapperProps> = ({ isLoading = false, children }) => {
  const theme = useTheme();

  return (
    <Spin spinning={isLoading}>
      <div className={theme === Themes.DARK ? cx('border-wrapper') : ''}>
        <div className={theme === Themes.DARK ? cx('border-wrapper-inner') : ''}>{children}</div>
      </div>
    </Spin>
  );
};

export default BorderWrapper;
