import React, { useState, useEffect, useRef, useMemo } from 'react';
import BorderWrapper from '@/components/BorderWrapper';
import {
  Form,
  Select,
  Input,
  Tag,
  Breadcrumb,
  useBreadcrumbRoutes,
  useUpdate,
  Table,
  EllipsisSpan,
  Button,
  Empty,
  Modal,
  CustomFilter,
} from '@maxtropy/components';
import {
  Card,
  Statistic,
  Row,
  Col,
  Divider,
  Avatar,
  Space,
  TableColumnsType,
  InputRef,
  PaginationProps,
  Pagination,
} from 'antd';
import { FallOutlined, RiseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  AlarmLevel,
  AlarmLevelColorDisplay,
  AlarmLevelDisplay,
  AlarmLogRequest,
  AlarmLogResponse,
  readAlarmLog,
  getAlarmLogList,
  AlarmLogStatistics,
  getAlarmLogStatistics,
} from '@/api/alarm';
import { CabinetWithCircuitResponse, getCabinetWithCircuitList } from '@/api/circuit';
import { fetchOuList, getOuId, Ou } from '@/api/ou';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';
import { qingflowWorkDetailPath } from './const';
import { isEmpty } from 'lodash-es';
import styles from './index.module.scss';
import WorkOrderModal from './WorkOrderModal';
import { apiV2AlarmFaultSignboardRecoveryPost } from '@maxtropy/dmes-apis-v2';

type SearchParams = Omit<AlarmLogRequest, 'page' | 'size' | 'alarmStartTime' | 'alarmEndTime'>;

export default function AlarmRecordList() {
  const routesContext = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [updateState, forceUpdate] = useUpdate();
  const [searchParams, setSearchParams] = useState<SearchParams>({} as SearchParams);
  const hasCreateWorkOrderPermission = useHasPermission(PermissionsType.B_WORKER_ORDER_CREATE);
  const hasSubOrderOtherPermission = useHasPermission(PermissionsType.B_SUBMIT_ORDER_OTHER);

  // const pagingInfo = usePaging(50);
  // const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [cabinets, setCabinets] = useState<CabinetWithCircuitResponse[]>([]);
  const [ouList, setOuList] = useState<Ou[]>([]);
  const [alarmLogStatistics, setAlarmLogStatistics] = useState<AlarmLogStatistics | undefined>();
  // 分页配置
  const [totalCount, setTotalCount] = useState<number>(); // 总页数
  const [currentPage, setCurrentPage] = useState<number>(1); // 当前页数
  const [pageSize, setPageSize] = useState<number>(50); // 每页多少条
  const [isSortValue, setIsSortValue] = useState<boolean>(false); // 是否传sortvalue
  const [sortValues, setSortValues] = useState<any[]>();

  //先定义两个空的数组，用于存放选中的id和数据
  const [checkedData, setCheckedData] = useState<any>([]); // 选中的数据
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);

  // 近 30 日环比
  const monthlyRatio = useMemo(() => {
    if (alarmLogStatistics) {
      return alarmLogStatistics.lastAlarmNum
        ? (
            (((alarmLogStatistics.alarmNum as number) - alarmLogStatistics.lastAlarmNum) /
              alarmLogStatistics.lastAlarmNum) *
            100
          ).toString()
        : '--';
    }
    return '--';
  }, [alarmLogStatistics]);
  const [alarmLogList, setAlarmLogList] = useState<AlarmLogResponse[]>([]);
  const deviceInputRef = useRef<InputRef>(null);
  const circuitInputRef = useRef<InputRef>(null);
  // 工单模态框
  const [showWorkOrderModal, setShowWorkOrderModal] = useState(false);
  const [ruleLogId, setRuleLogId] = useState<string>('');

  const forceTableUpdate = () => {
    forceUpdate();
    setCurrentPage(1);
  };

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setIsSortValue(false);
    setCurrentPage(1);
  };

  const onReset = () => {
    setSearchParams({});
    setCurrentPage(1);
  };

  const readLog = (alarmId: number | string) => {
    readAlarmLog(alarmId).then(res => {
      if (res) {
        Modal.success({
          title: '已读！',
          okText: '确定',
          onOk() {
            forceUpdate();
          },
          onCancel() {
            forceUpdate();
          },
        });
      }
    });
  };

  const renderMonthlyRatioIcon = () => {
    if (parseFloat(monthlyRatio) > 0) {
      return <RiseOutlined />;
    } else if (parseFloat(monthlyRatio) < 0) {
      return <FallOutlined />;
    } else {
      return null;
    }
  };

  const renderRankListBackground = (index: number) => {
    if (index === 0) {
      return '#BA8D0F';
    } else if (index === 1) {
      return '#A09E9A';
    } else if (index === 2) {
      return '#77442B';
    } else {
      return '#4F4F4F';
    }
  };

  const columns: TableColumnsType<AlarmLogResponse> = [
    {
      title: '设备编号',
      dataIndex: 'deviceCode',
      ellipsis: { showTitle: true },
      fixed: 'left' as const,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '关联回路管网',
      dataIndex: 'circuitName',
      ellipsis: { showTitle: true },
      fixed: 'left' as const,
      render: (v: string, record: AlarmLogResponse) => {
        return (
          <Button
            type="link"
            onClick={() => {
              window.open(
                `/dmes/statistics/circuit?id=${record.circuitId}&time=${dayjs(record.alarmTime).valueOf()}`,
                '_blank'
              );
            }}
          >
            <EllipsisSpan value={v} />
          </Button>
        );
      },
    },
    {
      title: '报警信息',
      dataIndex: 'alarmName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '报警等级',
      dataIndex: 'alarmLevel',
      ellipsis: { showTitle: true },
      render: (v: AlarmLevel) => (
        <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
          {AlarmLevelDisplay[v]}
        </Tag>
      ),
    },
    {
      title: '报警时间',
      dataIndex: 'alarmTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
    },
    {
      title: '恢复时间',
      dataIndex: 'recoveryTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '未恢复'} />,
    },
    {
      title: '所属配电柜/拓扑',
      dataIndex: 'distributionCabinetName',
      ellipsis: { showTitle: true },
      render: (v: string) => {
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '所属运营单元',
      dataIndex: 'ouName',
      ellipsis: { showTitle: true },
      render: (v: string) => {
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '操作',
      fixed: 'right' as const,
      width: 290,
      render: (v: any, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              disabled={!!record?.readTime}
              onClick={() => {
                readLog(record.id);
              }}
            >
              {record?.readTime ? '已读' : '确认已读'}
            </Button>
            {hasSubOrderOtherPermission && (
              <Button
                type="link"
                href={`${
                  window.ALARMWORKORDER
                }/workOrder/repairWorkOrder/workOrderList/workOrderAdd?problemSource=${10}&operationUnitCode=${
                  record.ouId
                }&alarmId=${record.id}&alarmTypeCode=-1`}
              >
                发起工单
              </Button>
            )}

            {hasCreateWorkOrderPermission ? (
              record?.ruleLogWorkOrderId ? (
                <Button type="link" href={qingflowWorkDetailPath} target="_blank">
                  查看工单
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    setRuleLogId(record.id.toString());
                    setShowWorkOrderModal(true);
                  }}
                >
                  创建工单
                </Button>
              )
            ) : null}
            <Button
              type="link"
              onClick={() => {
                window.open(`/dmes/alarm/record/detail/${record.id}`, '_blank');
              }}
            >
              查看
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    getAlarmLogStatistics().then(res => {
      setAlarmLogStatistics(res);
    });
  }, []);

  useEffect(() => {
    getOuId().then(res => {
      const ouType = res?.filter(i => i.key === 'MICRONET')[0]?.id;
      if (!ouType) {
        return;
      } else {
        fetchOuList(ouType).then(res => {
          setOuList(res);
        });
      }
    });
  }, []);

  useEffect(() => {
    getCabinetWithCircuitList().then(res => {
      setCabinets(res);
    });
  }, []);

  useEffect(() => {
    setTableLoading(true);
    getAlarmLogList({
      ...searchParams,
      sortValues: isSortValue ? sortValues : undefined,
      page: currentPage,
      size: pageSize,
    })
      .then(res => {
        if (res) {
          if (res.useSearchAfter) {
            setSortValues(res.sortValues);
            setIsSortValue(true);
          } else {
            setSortValues(undefined);
            setIsSortValue(false);
          }
          setAlarmLogList(res.list);
          setTotalCount(res.total);
        }
      })
      .finally(() => setTableLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, searchParams, setTotalCount, updateState]);

  const PaginationOnChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // 当pageSize发生变化的时候将页码重置为第一条（因为要重新计算页面乘以当前页是否大于10000）
  useEffect(() => {
    if (pageSize) {
      setCurrentPage(1);
    }
  }, [pageSize]);

  const tableSelect = (record: any, selected: any) => {
    //如果选中某一行，把选中的这行数据及id分别存到checkedData、selectedKeys中
    if (selected) {
      const arr = checkedData.map((item: any) => item.id);
      setSelectedKeys([...arr, record.id]);
      setCheckedData([...checkedData, record]);
      //取消选中，则在checkedData、selectedKeys中过滤掉这条数据
    } else {
      const newData = checkedData.filter((item: any) => item.id !== record.id);
      setCheckedData(newData);
      const arr = newData.map((item: any) => {
        return item.id;
      });
      setSelectedKeys([...arr]);
    }
  };
  // 表格全选/取消全选
  const tableSelectAll = (selected: boolean, selectedRows: any, changeRows: any) => {
    if (!selected) {
      changeRows.forEach((row: any) => {
        const newData = checkedData.filter((item: any) => item.id !== row.id);
        setCheckedData(newData);
      });
      const arr = changeRows.map((item: any) => item.id);
      const newArr = selectedKeys.filter((item: any) => !arr.some((ele: any) => ele === item));
      setSelectedKeys([...newArr]);
    }
    //全选，把
    else {
      const arr = changeRows.map((item: any) => item.id);
      setCheckedData([...checkedData, ...changeRows]);
      setSelectedKeys([...selectedKeys, ...arr]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys, //展示选中的数据
    onSelect: tableSelect, //单条数据取消/选中的回调
    onSelectAll: tableSelectAll, //全选/取消全选的回调
  };

  const adjustRestore = () => {
    Modal.confirm({
      title: `确定解除报警？`,
      onOk: () => {
        apiV2AlarmFaultSignboardRecoveryPost({
          alarmInfo: checkedData.map((m: any) => ({
            id: m.id,
            sceneType: m.sceneType,
          })),
        }).then(_ => {
          setSelectedKeys([]);
          setCheckedData([]);
          forceUpdate();
        });
      },
    });
  };

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <Button
          onClick={() => {
            setIsSortValue(true);
            setCurrentPage(currentPage - 1);
          }}
        >
          上一页
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button
          onClick={() => {
            setIsSortValue(true);
            setCurrentPage(currentPage + 1);
          }}
        >
          下一页
        </Button>
      );
    }
    return originalElement;
  };

  return (
    <div className="page">
      <div className="page__header">
        <Breadcrumb routes={routesContext?.routes ?? []} />
      </div>
      <BorderWrapper>
        <div style={{ marginBottom: '10px' }}>
          <Row gutter={8}>
            <Col span={3}>
              <Card style={{ height: '100%' }}>
                <Statistic
                  title="当前未恢复报警总数"
                  value={alarmLogStatistics?.notRecoverNum ? alarmLogStatistics?.notRecoverNum : '0'}
                  valueStyle={{
                    color: 'var(--mx-error-color)',
                    marginTop: '16px',
                    fontSize: '38px',
                    fontWeight: 'bold',
                  }}
                  suffix={<span style={{ color: 'var(--mx-text-base-color)', fontSize: 14 }}>个</span>}
                />
              </Card>
            </Col>
            <Col span={9}>
              <Card style={{ height: '100%' }}>
                <Row gutter={16}>
                  <Col span={5}>
                    <Space direction="vertical">
                      <Statistic
                        title="近30日报警数"
                        value={alarmLogStatistics?.alarmNum ? alarmLogStatistics?.alarmNum : '0'}
                        valueStyle={{
                          color: 'var(--mx-error-color)',
                          marginTop: '16px',
                          // fontSize: '28px',
                          fontWeight: 'bold',
                        }}
                        suffix={<span style={{ color: 'var(--mx-text-base-color)', fontSize: 14 }}>个</span>}
                      />
                      <Statistic
                        value={monthlyRatio}
                        precision={2}
                        valueStyle={{
                          color: parseFloat(monthlyRatio) > 0 ? 'var(--mx-success-color)' : 'var(--mx-error-color)',
                          fontSize: 14,
                        }}
                        prefix={
                          <Space>
                            <span style={{ color: 'var(--mx-text-base-color)' }}>环比</span>
                            {renderMonthlyRatioIcon()}
                          </Space>
                        }
                        suffix={monthlyRatio === '--' ? '' : '%'}
                      />
                    </Space>
                  </Col>
                  <Col span={1}>
                    <Divider type="vertical" style={{ height: '100%' }} />
                  </Col>
                  <Col span={6}>
                    <Statistic
                      title="最高级"
                      value={alarmLogStatistics?.topAlarmNum ? alarmLogStatistics?.topAlarmNum : '0'}
                      valueStyle={{
                        color: 'var(--mx-error-color)',
                        marginTop: '16px',
                        // fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                      suffix={<span style={{ color: 'var(--mx-text-base-color)', fontSize: 14 }}>个</span>}
                    />
                  </Col>
                  <Col span={6}>
                    <Statistic
                      title="高级"
                      value={alarmLogStatistics?.highAlarmNum ? alarmLogStatistics?.highAlarmNum : '0'}
                      valueStyle={{
                        color: 'var(--mx-warning-color)',
                        marginTop: '16px',
                        // fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                      suffix={<span style={{ color: 'var(--mx-text-base-color)', fontSize: 14 }}>个</span>}
                    />
                  </Col>
                  <Col span={6}>
                    <Statistic
                      title="中级"
                      value={alarmLogStatistics?.middleAlarmNum ? alarmLogStatistics?.middleAlarmNum : '0'}
                      valueStyle={{
                        color: 'var(--mx-info-color)',
                        marginTop: '16px',
                        // fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                      suffix={<span style={{ color: 'var(--mx-text-base-color)', fontSize: 14 }}>个</span>}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="近30日高频报警回路排名" style={{ height: '100%' }} type="inner">
                <Row gutter={[16, { xs: 2, sm: 4, md: 6, lg: 8 }]}>
                  {!isEmpty(alarmLogStatistics?.rankList) ? (
                    [...alarmLogStatistics?.rankList!]
                      .sort((a, b) => (b.alarmNum ?? 0) - (a.alarmNum ?? 0))
                      ?.map((c, i) => (
                        <Col span={12} key={c.circuitId}>
                          <Space>
                            <Avatar size="small" style={{ backgroundColor: renderRankListBackground(i) }}>
                              {i + 1}
                            </Avatar>
                            <span>{c.circuitName}</span>
                            <span>
                              <span style={{ color: 'var(--mx-error-color)' }}>{c.alarmNum.toString()}</span>
                              &nbsp;次
                            </span>
                          </Space>
                        </Col>
                      ))
                  ) : (
                    <Col span={24}>
                      <Empty description="近期无高频报警回路" />
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        </div>

        <div className="page__filter">
          <CustomFilter form={form} onFinish={value => onFinish(value as SearchParams)} onReset={onReset}>
            <Form.Item name="deviceCode" label="设备编号">
              <Input
                ref={deviceInputRef}
                onPressEnter={() => {
                  if (deviceInputRef.current) {
                    form.submit();
                    deviceInputRef.current.blur();
                  }
                }}
                onBlur={() => form.submit()}
                placeholder="请输入设备编号"
              />
            </Form.Item>
            <Form.Item name="circuitName" label="回路管网名称">
              <Input
                ref={circuitInputRef}
                onPressEnter={() => {
                  if (circuitInputRef.current) {
                    form.submit();
                    circuitInputRef.current.blur();
                  }
                }}
                onBlur={() => {
                  form.submit();
                }}
                placeholder="请输入回路管网名称"
              />
            </Form.Item>
            <Form.Item name="state" label="状态">
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                placeholder="请选择"
                options={[
                  {
                    value: '0',
                    label: '恢复',
                  },
                  {
                    value: '1',
                    label: '未恢复',
                  },
                ]}
              />
            </Form.Item>
            <Form.Item name="alarmLevels" label="报警等级">
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                optionFilterProp="label"
                showSearch
                mode="multiple"
                allowClear
                placeholder="请选择"
                options={Object.entries(AlarmLevelDisplay).map(([k, v]) => ({
                  label: v,
                  value: k,
                }))}
              />
            </Form.Item>
            <Form.Item name="distributionCabinetId" label="所属配电柜/拓扑">
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                placeholder="请选择"
                options={cabinets.map(v => ({
                  value: v.id,
                  label: v.name,
                }))}
              />
            </Form.Item>
            <Form.Item name="ouId" label="所属运营单元">
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                placeholder="请选择"
                showSearch
                optionFilterProp="label"
                options={ouList.map(v => ({
                  value: v.id,
                  label: v.name,
                }))}
              />
            </Form.Item>
          </CustomFilter>
        </div>

        <div className={styles.pageContent}>
          <div className={styles.alarmTable}>
            <Space size={16}>
              <Button type="primary" disabled={!selectedKeys.length} onClick={adjustRestore}>
                解除报警
              </Button>
              <div style={{ marginBottom: 16 }}>
                <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
                <span style={{ opacity: 0.8, marginLeft: 4 }}>请确认设备绑定唯一回路管网</span>
              </div>
            </Space>

            <Table
              rowKey="id"
              sticky
              scroll={{ x: 1900 }}
              loading={tableLoading}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              dataSource={alarmLogList}
            />
          </div>
          {totalCount ? (
            <div className={styles.pagination}>
              <Pagination
                showTotal={_ => (
                  <Space size={10}>
                    <div className={styles.total}>{`共${totalCount}条`}</div>
                    {currentPage * pageSize >= 10000 ? (
                      <Button onClick={() => setCurrentPage(1)}>返回第一页</Button>
                    ) : null}
                  </Space>
                )}
                disabled={currentPage * pageSize >= 10000 ? true : false}
                itemRender={itemRender}
                showSizeChanger
                current={currentPage}
                onChange={PaginationOnChange}
                pageSize={pageSize}
                total={totalCount < 10000 ? totalCount : currentPage * pageSize >= 10000 ? totalCount : 10000}
                defaultPageSize={50}
                pageSizeOptions={['10', '20', '30', '50', '100']}
              />
            </div>
          ) : null}
        </div>

        <WorkOrderModal
          id={ruleLogId}
          open={showWorkOrderModal}
          forceUpdate={forceTableUpdate}
          closeWorkOrderModal={() => {
            setShowWorkOrderModal(false);
          }}
        />
      </BorderWrapper>
    </div>
  );
}
